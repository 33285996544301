<template>
  <layout-center>
    <form>
      <v-card v-if="item">
        <v-card-title>
          <div class="title">
            {{ $lang(item.id ? "UPDATE" : "NEW") + " " + $lang("COUPON") }}
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="d-flex flex-row">
          <v-text-field
            :label="$lang('code')"
            v-model="item.uid"
            class="mr-2"
          />
          <v-select
            :label="$lang('type')"
            :items="typeItems"
            v-model="item.type"
          ></v-select>
        </v-card-text>
        <v-card-text class="d-flex flex-row">
          <v-text-field
            :label="$lang('amount')"
            v-model="item.amount"
            class="mr-2"
          />
          <v-select
            :label="$lang('currency')"
            :items="currencyItems"
            v-model="item.currency"
          ></v-select>
        </v-card-text>
        <v-card-text class="d-flex flex-row">
          <v-text-field
            :label="$lang('vCoin')"
            v-model="item.data.vcoin"
            type="number"
            hide-spin-buttons
            class="mr-2"
          />
          <v-text-field
            :label="$lang('gCoin')"
            v-model="item.data.gcoin"
            type="number"
            hide-spin-buttons
            class="ml-2"
          />
        </v-card-text>
        <v-card-text class="d-flex flex-row">
          <v-text-field
            :label="$lang('issued')"
            v-model="item.issued_count"
            type="number"
            hide-spin-buttons
            class="mr-2"
          />
          <v-text-field
            :label="$lang('redeemed')"
            v-model="item.redeemed_count"
            type="number"
            hide-spin-buttons
            class="ml-2"
          />
        </v-card-text>
        <v-card-text>
          <ExpireDateSelector :item="item" />
        </v-card-text>
        <v-divider></v-divider>
        <common-create-fields :item="item" variant="default" />
        <v-card-actions>
          <div class="text-xs-center">
            <v-btn outlined @click="doSubmit">{{
              $lang(item.id ? "UPDATE" : "CREATE")
            }}</v-btn>
            <v-btn text @click="$router.push({ name: 'dashboard-coupons' })">{{
              $lang("CANCEL")
            }}</v-btn>
          </div>
          <v-spacer></v-spacer>
          <v-btn v-if="item.id" color="red" outlined @click="handleDelete()">
            <v-icon>mdi-delete</v-icon> {{ $lang("REMOVE") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </layout-center>
</template>

<script>
import { mapGetters } from "vuex";
import LayoutCenter from "@/layouts/LayoutCenter.vue";
import CommonCreateFields from "@/app/dashboard/prep/modules/CommonCreateFields.vue";
import { axios } from "@/plugins/axios";
import ExpireDateSelector from "./ExpireDateSelector.vue";
import {
  youTubeIdFromLink,
  validateURL,
  validateYouTubeUrl,
} from "@/helpers/utils";

export default {
  name: "dashboard-coupons-create",
  components: {
    LayoutCenter,
    ExpireDateSelector,
    CommonCreateFields,
  },
  data() {
    return {
      typeItems: [
        { text: "Wallet", value: "wallet" },
        { text: "Sign Up", value: "signup" },
        { text: "Purchase", value: "purchase" },
      ],
      currencyItems: [{ text: "Indian Rupees", value: "INR" }],
      item: {
        data: {
          vcoin: 0,
          gcoin: 0,
        },
        language: null,
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      auth: "auth/auth",
    }),
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  methods: {
    handleDelete() {
      if (
        !confirm(
          `Are you sure to delete ${
            this.item.title ? this.item.title : this.item.id
          }?`
        )
      ) {
        return Promise.reject("CANCELED_BY_USER");
      }
      this.loding = true;
      return axios
        .delete(`/coupons/${this.item.id}`)
        .then((res) => {
          this.$router.replace({ name: "dashboard-coupons" });
          return Promise.resolve(res);
        })
        .catch((error) => {
          return Promise.reject(error.response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchItem(id) {
      return axios
        .get(`coupons/${id}`)
        .then((res) => {
          this.item = res.data;
        })
        .catch(() => {
          this.$router.replace({ name: "dashboard-coupons" });
        });
    },
    doSubmit() {
      // if (!this.item.ytid || this.item.ytid.trim().length < 11) {
      //   alert("youtube coupon id is required.");
      //   return;
      // }
      if (this.$route.params.id) {
        if (this.item.uid && this.item.uid.trim().length > 0) {
          this.item.uid = this.item.uid.trim().toLowerCase();
        }
        return axios
          .post(`coupons/${this.$route.params.id}`, this.item)
          .then((res) => {
            // this.item = res.data;
            // this.$router.replace({
            //   name: "dashboard-coupons-create",
            //   params: { id: this.$route.params.id },
            // });
            this.$router.replace({ name: "dashboard-coupons" });
          });
      } else {
        return axios
          .post(`coupons`, this.item)
          .then((res) => {
            this.$router.replace({
              name: "dashboard-coupons-create",
              params: { id: res.data.data.id },
            });
          })
          .catch(() => {
            // this.$router.replace({ name: "dashboard-coupons" });
          });
      }
    },
  },
  watch: {
    "$route.params.id"(id) {
      this.fetchItem(id);
    },
    "item.ytid"(val) {
      if (validateYouTubeUrl(val)) {
        this.loading = true;
        let id = youTubeIdFromLink(val);
        console.log("youTubeIdFromLink ID", id);
        setTimeout(() => {
          this.item.ytid = id;
          this.loading = false;
        }, 600);
      }
      this.item.ytid = val;
    },
  },
  created() {
    if (this.$route.params.id) {
      this.fetchItem(this.$route.params.id);
    }
  },
};
</script>
