var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.computedDateFormattedMomentjs,"label":"Expiry date","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.activePicker,"min":new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),"max":new Date(
        new Date().setDate(new Date().getDate() + 365 * 2) -
          new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10)},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"change":_vm.save},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }